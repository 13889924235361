const AllPhotos = [
    {
        "id": 1,
        "title": "Seaport Snow",
        "collection": 1,
        "location" : "New York, NY",
        "model": "",
        "imagePath": "/Collections/Motion/1.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 1600
        }
    },
    {
        "id": 2,
        "title": "Speed",
        "collection": 1,
        "imagePath": "/Collections/Motion/2.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/8",
            "aperature": 9.5,
            "iso": 100
        }
    },
    {
        "id": 3,
        "title": "Stargazing",
        "collection": 1,
        "imagePath": "/Collections/Motion/3.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "5",
            "aperature": 5.6,
            "iso": 1600
        }
    },
    {
        "id": 4,
        "title": "Astor Place",
        "collection": 1,
        "imagePath": "/Collections/Motion/4.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 120mm",
            "body": "Mamiya RB67",
            "sensor": "None",
            "lens": "Sekor C 127mm 3.8",
            "shutterSpeed": "15",
            "aperature": 11,
            "iso": 400
        }
    },
    {
        "id": 5,
        "title": "Delivery",
        "collection": 1,
        "imagePath": "/Collections/Motion/5.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/30",
            "aperature": 2.0,
            "iso": 3200
        }
    },
    {
        "id": 6,
        "title": "Lovey Doveys",
        "collection": 1,
        "imagePath": "/Collections/Motion/6.JPG",
        "about": {
            "format": "Film",
            "film": "Fujifilm Fuji Fujicolor Pro 400H 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/1000",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 7,
        "title": "Stargazing 2",
        "collection": 1,
        "imagePath": "/Collections/Motion/7.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "15",
            "aperature": 5.6,
            "iso": 800
        }
    },
    {
        "id": 8,
        "title": "Patrick",
        "collection": 1,
        "imagePath": "/Collections/Motion/8.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 120mm",
            "body": "Mamiya RB67",
            "sensor": "None",
            "lens": "Sekor C 127mm 3.8",
            "shutterSpeed": "1/60",
            "aperature": 3.8,
            "iso": 400
        }
    },
    {
        "id": 9,
        "title": "Ring",
        "collection": 1,
        "imagePath": "/Collections/Motion/9.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 120mm",
            "body": "Mamiya RB67",
            "sensor": "None",
            "lens": "Sekor C 127mm 3.8",
            "shutterSpeed": "1/400",
            "aperature": 3.8,
            "iso": 400
        }
    },
    {
        "id": 10,
        "title": "Stargazing 3",
        "collection": 1,
        "imagePath": "/Collections/Motion/10.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "30",
            "aperature": 8,
            "iso": 800
        }
    },
    {
        "id": 11,
        "title": "Architecture",
        "collection": 2,
        "imagePath": "/Collections/Shadows/1.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M9",
            "sensor": "CCD 18MP",
            "lens": "Nokton 50mm 1.5",
            "shutterSpeed": "1/1000",
            "aperature": 4.0,
            "iso": 200
        }
    },
    {
        "id": 12,
        "title": "Vape LOL",
        "collection": 2,
        "imagePath": "/Collections/Shadows/2.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/500",
            "aperature": 2.0,
            "iso": 400
        }
    },
    // {
    //     "id": 13,
    //     "title": "Ghost Town",
    //     "collection": 2,
    //     "imagePath": "/Collections/Shadows/3.JPG",
    //     "about": {
    //         "format": "Digital",
    //         "film": "None",
    //         "body": "M9",
    //         "sensor": "CCD 18MP",
    //         "lens": "Nokton 50mm 1.5",
    //         "shutterSpeed": "1/500",
    //         "aperature": 2.0,
    //         "iso": 160
    //     }
    // },
    {
        "id": 14,
        "title": "Yuumi",
        "collection": 2,
        "imagePath": "/Collections/Shadows/4.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/500",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 15,
        "title": "Pizza Store",
        "collection": 2,
        "imagePath": "/Collections/Shadows/5.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M6",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/60",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 16,
        "title": "Night Golf",
        "collection": 2,
        "imagePath": "/Collections/Shadows/6.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/500",
            "aperature": 1.4,
            "iso": 3200
        }
    },
    {
        "id": 17,
        "title": "Chinatown",
        "collection": 2,
        "imagePath": "/Collections/Shadows/7.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M10-P",
            "sensor": "CMOS 24MP",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/1000",
            "aperature": 1.4,
            "iso": 1600
        }
    },
    {
        "id": 18,
        "title": "Smoke",
        "collection": 2,
        "imagePath": "/Collections/Shadows/8.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M9",
            "sensor": "CCD 18MP",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/125",
            "aperature": 2.0,
            "iso": 1600
        }
    },
    // {
    //     "id": 19,
    //     "title": "Nheez In Paris",
    //     "collection": 2,
    //     "imagePath": "/Collections/Shadows/9.JPG",
    //     "about": {
    //         "format": "Film",
    //         "film": "Acros 100 35mm",
    //         "body": "M3",
    //         "sensor": "None",
    //         "lens": "Summilux 35mm 1.4 ASPH FLE",
    //         "shutterSpeed": "1/1000",
    //         "aperature": 1.4,
    //         "iso": 100
    //     }
    // },
    {
        "id": 20,
        "title": "Yuumi 2",
        "collection": 2,
        "imagePath": "/Collections/Shadows/10.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/500",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 21,
        "title": "Who's that?",
        "collection": 2,
        "imagePath": "/Collections/Shadows/11.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M9",
            "sensor": "CCD 18MP",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/60",
            "aperature": 1.4,
            "iso": 1600
        }
    },
    {
        "id": 22,
        "title": "Baby Powder",
        "collection": 2,
        "imagePath": "/Collections/Shadows/12.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M6",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 23,
        "title": "K-Town Shadow",
        "collection": 2,
        "imagePath": "/Collections/Shadows/13.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M9",
            "sensor": "CCD 18MP",
            "lens": "Nokton 50mm 1.5",
            "shutterSpeed": "1/3000",
            "aperature": 1.5,
            "iso": 160
        }
    },
    {
        "id": 24,
        "title": "Chinatown 2",
        "collection": 2,
        "imagePath": "/Collections/Shadows/14.JPG",
        "about": {
            "format": "Film",
            "film": "TriX 400TX",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 25,
        "title": "Chinatown 3",
        "collection": 2,
        "imagePath": "/Collections/Shadows/15.JPG",
        "about": {
            "format": "Film",
            "film": "TriX 400TX",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 400
        }
    },
    {
        "id": 26,
        "title": "Friends in Paris",
        "collection": 3,
        "imagePath": "/Collections/SOHO/1.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/500",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 27,
        "title": "QA in Paris",
        "collection": 3,
        "imagePath": "/Collections/SOHO/2.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/60",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 28,
        "title": "Smoke On Color",
        "collection": 3,
        "imagePath": "/Collections/SOHO/3.JPG",
        "about": {
            "format": "Digital",
            "film": "None",
            "body": "M9",
            "sensor": "CCD 18MP",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 1600
        }
    },
    {
        "id": 29,
        "title": "Candid On 5th Ave",
        "collection": 3,
        "imagePath": "/Collections/SOHO/4.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 30,
        "title": "Parisian Cafe & Bar",
        "collection": 3,
        "imagePath": "/Collections/SOHO/5.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summmilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 31,
        "title": "3AM @Fidi",
        "collection": 3,
        "imagePath": "/Collections/SOHO/6.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 32,
        "title": "QA in Fidi",
        "collection": 3,
        "imagePath": "/Collections/SOHO/7.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/125",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 33,
        "title": "MA in Fidi",
        "collection": 3,
        "imagePath": "/Collections/SOHO/8.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/500",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 34,
        "title": "4AM @Fidi",
        "collection": 3,
        "imagePath": "/Collections/SOHO/9.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/125",
            "aperature": 2.0,
            "iso": 800
        }
    },
    {
        "id": 35,
        "title": "Lovey Doveys Again",
        "collection": 3,
        "imagePath": "/Collections/SOHO/10.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 36,
        "title": "MA Moncler",
        "collection": 3,
        "imagePath": "/Collections/SOHO/11.JPG",
        "about": {
            "format": "Film",
            "film": "Cinestill 800T 35mm",
            "body": "M3",
            "sensor": "None",
            "lens": "Summilux 35mm 1.4 ASPH FLE",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 800
        }
    },
    {
        "id": 37,
        "title": "Frame Within A Mamiya Viewfinder",
        "collection": 3,
        "imagePath": "/Collections/SOHO/12.JPG",
        "about": {
            "format": "Film",
            "film": "Portra 400 35mm",
            "body": "M6",
            "sensor": "None",
            "lens": "Summilux 50mm 1.4 ASPH",
            "shutterSpeed": "1/250",
            "aperature": 1.4,
            "iso": 400
        }
    }
]

export default AllPhotos