import React from 'react'
import Particle from '../components/Particle'
import Footer2 from '../components/footer/Footer2'
import Nav2 from '../components/nav/Nav2'
import MyResume from '../components/resume/MyResume'
import Contact from '../components/contact/Contact'

const Resume = () => {
  return (
    <div>
      <Particle/>
      <Nav2/>
      <MyResume/>
      <Contact/>
      <Footer2/>
    </div>
  )
}

export default Resume