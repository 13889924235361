import React from 'react'
import Contactt from '../components/contact/Contact'
import Nav from '../components/nav/Nav'
import Footer from '../components/footer/Footer'
import Particle from '../components/Particle'

const Contact = () => {
  return (
    <div>
        <Particle/>
        <Nav/>
        <Contactt/>
        <Footer/>
    </div>
  )
}

export default Contact